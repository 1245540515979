<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      size="md"
      class="rounded-0"
      centered
      no-close-on-backdrop
      :title="type == 'dahil' ? 'Dahil Hizmet Ekle' : 'Hariç Hizmet Ekle'"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Hizmet Seçiniz">
              <v-select
                v-model="form.hizmet_k_no"
                :options="hizmetler"
                :reduce="(hizmet) => hizmet.k_no"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Ekle
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="handlerRemove(form._id)">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" />
            Sil
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      hizmetler: [],
      form: {
        k_no: null,
        type: 'dahil',
        hizmet_k_no: null,
        fiyat: 0,
      },
    };
  },
  async created() {
    await this.handlerGetAllData();
  },
  methods: {
    async handlerGetAllData() {
      this.show = true;
      this.hizmetler = [];
      await this.$store.dispatch('ekstraHizmetListele');
      const hizmetler = await this.$store.getters.getEkstraHizmetler;

      hizmetler.forEach((el) => {
        this.hizmetler.push({ k_no: el.k_no, title: el.icerik['tr'].baslik });
      });
      this.show = false;
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('my-modal');
      });
      this.$emit('closeModal', true);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.show = true;
      this.form.type = this.type;
      this.form.k_no = this.data.k_no;
      this.$store
        .dispatch('urunDahilHaricHizmetGuncelle', this.form)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Ekleme Başarılı',
              },
            });
            this.show = false;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Uyarı`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: 'Daha önce eklenmiş.',
              },
            });
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
    handlerRemove(id) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('urunDahilHaricHizmetSil', id).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
              this.hideModal();
            }
          });
        }
      });
    },
  },
  mounted() {
    this.handlerGetAllData();
  },
  watch: {
    openModal: {
      handler(val) {
        if (val == true) {
          this.showModal();
          this.handlerGetAllData();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
